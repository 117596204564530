import { db } from "../../firebase";

import {
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
  addDoc,
  updateDoc,
  writeBatch,
  query,
  onSnapshot,
} from "firebase/firestore";

import { getStorage, ref, listAll } from "firebase/storage";

export const createUser = async (uid, userData) => {
  return await setDoc(doc(db, "users", uid), {
    userId: uid,
    userData,
  })
    .then(() => console.log("user Created"))
    .catch((err) => console.log("error", err));
};

export const createProduct = async (productData) => {
  // create the product based on the product data
  const productId = await addDoc(collection(db, "products"), productData)
    .then((doc) => {
      return doc.id;
    })
    .catch((err) => {
      alert("error", err);
      return false;
    });

  // update the product created above with its product id
  const productRef = doc(db, "products", productId);
  return await updateDoc(productRef, { productId: productId })
    .then(() => {
      return true;
    })
    .catch((err) => {
      alert("error", err);
      return false;
    });
};

export const updateUserCollection = async () => {
  let usersArray = [];
  const querySnapshot = await getDocs(collection(db, "users"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    usersArray.push(doc.id);
  });
  const batch = writeBatch(db);
  const activity = [
    {
      voted: false,
      voteTime: "",
      updateVersion: 1,
    },
  ];

  for (let user of usersArray) {
    const userRef = doc(db, "users", user);
    batch.update(userRef, { stores: activity });
  }
  await batch.commit();
};

export const updateProductCollection = async () => {
  let productsArray = [];
  const querySnapshot = await getDocs(collection(db, "products"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    productsArray.push(doc.id);
  });
  const batch = writeBatch(db);

  for (let product of productsArray) {
    const productRef = doc(db, "products", product);
    await updateDoc(productRef, { productId: product });
  }
};

export const voteCorrectPrice = async (type, productId, key, userId) => {
  const productRef = doc(db, "products", productId);
  const docSnap = await getDoc(productRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    if (type === "add") {
      const currentVotes = data.stores[key].correctPriceVotes;
      const newVotes = currentVotes + 1;
      data.stores[key].correctPriceVotes = newVotes;
    } else {
      const currentVotes = data.stores[key].wrongPriceVotes;
      const newVotes = currentVotes + 1;
      data.stores[key].wrongPriceVotes = newVotes;
    }
    data.stores[key].lastVoteTime = new Date();
    data.stores[key].userVotes?.push(userId);
    await updateDoc(productRef, data, { merge: true });
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};

export const updateStorePrice = async (productId, key, storePrice) => {
  const productRef = doc(db, "products", productId);
  const docSnap = await getDoc(productRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    data.stores[key].currentPrice = storePrice;
    data.stores[key].lastUpdateTime = new Date();
    data.stores[key].userVotes = [];
    data.stores[key].lastVoteTime = new Date();
    data.stores[key].wrongPriceVotes = 0;
    data.stores[key].correctPriceVotes = 0;

    await updateDoc(productRef, data, { merge: true });
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};

export const getTotalNumberOfUsers = (setTotalUsers) => {
  const q = query(collection(db, "users"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let users = [];

    querySnapshot.forEach((doc) => {
      users.push(doc.data());
    });
    setTotalUsers(users.length);
  });
  return;
};

export const getTotalPriceUploads = (setTotalPriceUploads) => {
  const storage = getStorage();

  const listRef = ref(storage, "Prices/");
  let priceUploads = [];
  // Find all the prefixes and items.
  listAll(listRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        // All the items under listRef
        priceUploads.push(itemRef._location.path);
      });
      const totalPriceUploads = priceUploads.length;
      setTotalPriceUploads(totalPriceUploads);
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.log("Error:", error);
    });
};

// Create a reference under which you want to list
