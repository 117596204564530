import React, { useRef, useState, useEffect } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { Button, Alert, Table } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import categoryOptions from "../helpers/categoryOptions";
import NavBar from "../NavBar/NavBar";
import {
	createProduct,
	updateUserCollection,
	updateProductCollection,
	getTotalNumberOfUsers,
	getTotalPriceUploads,
} from "../helpers/source";
import loadingLogo from "../../Images/loading.svg";
import UpdatePrice from "./UpdatePrice";
import axios from "axios";

export default function AdminHome(props) {
	// *********** Accordion code *********************
	const Accordion = styled((props) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
	}));

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255, 255, 255, .05)"
				: "rgba(0, 0, 0, .03)",
		flexDirection: "row-reverse",
		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(90deg)",
		},
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(2),
		borderTop: "1px solid rgba(0, 0, 0, .125)",
	}));

	const [expanded, setExpanded] = useState("");

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	// ***********************************************************

	const { activeUser, setActiveUser, productData } = props;
	const [loading, setLoading] = useState(false);
	const productRef = useRef();
	const productURLRef = useRef();
	const allIndiaPriceRef = useRef();
	const bombaySpicesPriceRef = useRef();
	const chaloFreshcoPriceRef = useRef();
	const superstorePriceRef = useRef();
	const royalIndiaSMPriceRef = useRef();
	const walmartPriceRef = useRef();
	const [success, setSuccess] = useState(false);
	const [totalUsers, setTotalUsers] = useState("");
	const [productCategory, setProductCategory] = useState();
	const [error, setError] = useState("");
	const [totalPriceUploads, setTotalPriceUploads] = useState("");

	// build up product Array

	let products = [];

	productData.map((data, key) =>
		products.push({ label: data.productName, productId: data.productId })
	);

	//function to create a product in db
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (
			!productCategory ||
			productRef.current.value === "" ||
			productURLRef.current.value === ""
		) {
			setError("All fields are mandatory.");
			return false;
		}
		const checkProduct = products.forEach((product) => {
			if (product.label === productRef.current.value) {
				return true;
			}
		});

		if (checkProduct) {
			setError("Product already exists.");
			return false;
		}
		setLoading(true);

		let productData = {
			category: productCategory,
			productName: productRef.current.value,
			productURL: productURLRef.current.value,
			active: true,
			city: "Edmonton, AB",
			dateAdded: Date.now(),
			addedBy: activeUser.userData.email,
			stores: [
				{
					correctPriceVotes: 0,
					wrongPriceVotes: 0,
					storeName: "All India",
					mapLink:
						"https://www.google.com/maps/place/All+India+Supermarket/@53.454098,-113.4102041,17z/data=!3m1!4b1!4m5!3m4!1s0x53a0192230921925:0xbd65efb3f9caee5d!8m2!3d53.4541956!4d-113.4080102",
					currentPrice: allIndiaPriceRef?.current.value
						? allIndiaPriceRef.current.value
						: "n/a",
					lastUpdateVersion: 1,
					lastUpdateTime: new Date(),
					lastVoteTime: new Date(),
					userVotes: [],
				},
				{
					correctPriceVotes: 0,
					wrongPriceVotes: 0,
					storeName: "Bombay Spices",
					mapLink:
						"https://www.google.com/maps/place/Bombay+Spices/@53.4697256,-113.4721389,12z/data=!4m5!3m4!1s0x53a01ed54a707ca9:0x2de01ba8f8366b9c!8m2!3d53.4662853!4d-113.4717289",
					currentPrice: bombaySpicesPriceRef?.current.value
						? bombaySpicesPriceRef.current.value
						: "n/a",
					lastUpdateVersion: 1,
					lastUpdateTime: new Date(),
					lastVoteTime: new Date(),
					userVotes: [],
				},
				{
					correctPriceVotes: 0,
					wrongPriceVotes: 0,
					storeName: "Chalo Freshco",
					mapLink:
						"https://www.google.com/maps/place/Chalo+FreshCo+Tamarack/@53.455558,-113.371208,17z/data=!3m1!4b1!4m5!3m4!1s0x53a0195ec0ae9bc7:0xc358d46b0a0fb51!8m2!3d53.455558!4d-113.369014",
					currentPrice: chaloFreshcoPriceRef?.current.value
						? chaloFreshcoPriceRef.current.value
						: "n/a",
					lastUpdateVersion: 1,
					lastUpdateTime: new Date(),
					lastVoteTime: new Date(),
					userVotes: [],
				},
				{
					correctPriceVotes: 0,
					wrongPriceVotes: 0,
					storeName: "Royal India SM",
					mapLink:
						"https://www.google.com/maps/place/Royal+Indian+Supermarket+%26+Kitchen/@53.4671087,-113.4781104,17z/data=!3m1!4b1!4m5!3m4!1s0x53a01f42def39d53:0x2faef075f940cb90!8m2!3d53.4671087!4d-113.4759164",
					currentPrice: royalIndiaSMPriceRef.current.value
						? royalIndiaSMPriceRef.current.value
						: "n/a",
					lastUpdateVersion: 1,
					lastUpdateTime: new Date(),
					lastVoteTime: new Date(),
					userVotes: [],
				},
				{
					correctPriceVotes: 0,
					wrongPriceVotes: 0,
					storeName: "Superstore",
					currentPrice: superstorePriceRef?.current.value
						? superstorePriceRef.current.value
						: "n/a",
					mapLink:
						"https://www.google.com/maps/search/Superstore/@53.4555528,-113.4041298,13z/data=!3m1!4b1",
					lastUpdateVersion: 1,
					lastUpdateTime: new Date(),
					lastVoteTime: new Date(),
					userVotes: [],
				},
				{
					correctPriceVotes: 0,
					wrongPriceVotes: 0,
					storeName: "Walmart",
					mapLink:
						"https://www.google.com/maps/search/walmart/@53.4688687,-113.4519393,12.5z",
					currentPrice: walmartPriceRef?.current?.value
						? walmartPriceRef?.current?.value
						: "n/a",
					lastUpdateVersion: 1,
					lastUpdateTime: new Date(),
					lastVoteTime: new Date(),
					userVotes: [],
				},
			],
		};

		// try {
		//   await createProduct(productData);
		//   setSuccess(true);
		//   setError("Product added");
		//   setProductCategory("");
		// } catch (error) {
		//   alert(error);
		//   setError("Error adding product");
		// }

		setTimeout(() => {
			setLoading(false);
		}, 1500);
	};

	const handleAddAnother = (e) => {
		e.preventDefault();
		setError(false);
		setSuccess(false);
	};

	const handleUpdateUserCollection = async (e) => {
		e.preventDefault();
		await updateUserCollection();
	};

	const handleUpdateProductCollection = async (e) => {
		e.preventDefault();
		await updateProductCollection();
	};

	const handleSendRegistrationEmail = async (e) => {
		e.preventDefault();
		const userData = {
			userEmail: "bunty2831@gmail.com",
			userName: "admin",
		};
		const baseUrl =
			"https://northamerica-northeast1-ca-comparify.cloudfunctions.net/api";
		// "http://localhost:5000/ca-comparify/northamerica-northeast1/api";
		const url = `${baseUrl}/api/registration-email/`;
		await axios.post(url, userData);
	};

	const fetchTotalUsers = async () => {
		const users = await getTotalNumberOfUsers(setTotalUsers);
		const priceUploads = await getTotalPriceUploads(setTotalPriceUploads);
		return users;
	};

	fetchTotalUsers();

	return (
		<div className="signup-page">
			<NavBar activeUser={activeUser} setActiveUser={setActiveUser} />
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
				style={{ margin: "10px" }}
			>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<Typography
						className="sign-up"
						style={{ fontWeight: "550", fontSize: "16px" }}
					>
						Add a product
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{loading ? (
						<img
							src={loadingLogo}
							className="logo"
							style={{
								height: "10rem",
								width: "100%",
								margin: "10px auto",
							}}
							alt=""
						/>
					) : success ? (
						<Box
							component="form"
							className="form-container"
							style={{ padding: "5% 5% 3% 5%" }}
							sx={{
								"& .MuiTextField-root": { m: 1, width: "25ch" },
							}}
							noValidate
							autoComplete="off"
						>
							<i className="fa-solid fa-circle-check"></i>
							<Alert variant="success">{error}</Alert>
							<Button
								className="signup-button"
								onClick={(e) => handleAddAnother(e)}
							>
								Add another
							</Button>
						</Box>
					) : (
						<Box
							component="form"
							className="form-container"
							sx={{
								"& .MuiTextField-root": { m: 1, width: "25ch" },
							}}
							noValidate
							style={{ padding: "5% 5% 3% 5%" }}
							autoComplete="off"
						>
							<Autocomplete
								id="auto-complete-demo 2"
								value={productCategory}
								options={categoryOptions.map((option) => option.label)}
								onSelect={(e) => setProductCategory(e.target.value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Select Category"
										color="warning"
										className="input-text"
										InputProps={{
											...params.InputProps,
											type: "search",
										}}
										style={{
											margin: "5px auto",
											border: error && "2px solid red",
										}}
										required
									/>
								)}
							/>
							<TextField
								required
								className="input-text"
								id="filled-product-input"
								color="warning"
								label="Enter product name"
								helperText="ex: Aashirvaad Multigrain Atta 10LB"
								type="text"
								variant="filled"
								style={{ margin: "5px auto", border: error && "2px solid red" }}
								inputRef={productRef}
							/>
							<TextField
								required
								className="input-text"
								id="filled-productURL-input"
								color="warning"
								label="Paste product URL"
								helperText="from DB else leave blank"
								type="text"
								variant="filled"
								style={{ margin: "5px auto", border: error && "2px solid red" }}
								inputRef={productURLRef}
							/>

							<TextField
								id="filled-username-1"
								className="input-text"
								variant="filled"
								color="warning"
								label={`All India $`}
								type="number"
								inputRef={allIndiaPriceRef}
								style={{ margin: "5px auto" }}
							/>
							<TextField
								id="filled-number-2"
								className="input-text"
								label={`Bombay Spices $`}
								type="number"
								inputRef={bombaySpicesPriceRef}
								style={{ margin: "5px auto" }}
								variant="filled"
								color="warning"
							/>
							<TextField
								id="filled-number-3"
								className="input-text"
								label={`Chalo Freshco $`}
								type="number"
								inputRef={chaloFreshcoPriceRef}
								style={{ margin: "5px auto" }}
								variant="filled"
								color="warning"
							/>
							<TextField
								id="filled-number-5"
								className="input-text"
								label={`Royal Indian SM $`}
								type="number"
								inputRef={royalIndiaSMPriceRef}
								style={{ margin: "5px auto" }}
								variant="filled"
								color="warning"
							/>
							<TextField
								id="filled-number-4"
								className="input-text"
								label={`Superstore $`}
								type="number"
								inputRef={superstorePriceRef}
								style={{ margin: "5px auto" }}
								variant="filled"
								color="warning"
							/>

							<TextField
								id="filled-number-6"
								className="input-text"
								label={`Walmart $`}
								type="number"
								inputRef={walmartPriceRef}
								style={{ margin: "5px auto" }}
								variant="filled"
								color="warning"
							/>
							{error && <Alert variant="danger">{error}</Alert>}
							{loading ? (
								<img
									src={loadingLogo}
									className="logo"
									style={{
										height: "2rem",
										width: "2rem",
										margin: "0 5px",
									}}
									alt=""
								/>
							) : (
								<Button
									className="signup-button"
									onClick={(e) => handleSubmit(e)}
								>
									Submit
								</Button>
							)}
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel2"}
				onChange={handleChange("panel2")}
				style={{ margin: "10px" }}
			>
				<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
					<Typography
						className="sign-up"
						style={{ fontWeight: "550", fontSize: "16px" }}
					>
						Update Price
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						<UpdatePrice products={products} user={activeUser} />
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel3"}
				onChange={handleChange("panel3")}
				style={{ margin: "10px" }}
			>
				<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
					<Typography
						className="sign-up"
						style={{ fontWeight: "550", fontSize: "16px" }}
					>
						Master Functions
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						<Button
							variant="warning"
							className="admin-buttons"
							onClick={(e) => handleUpdateProductCollection(e)}
						>
							Update Product Collection
						</Button>
						<Button
							variant="warning"
							className="admin-buttons"
							onClick={(e) => handleUpdateUserCollection(e)}
						>
							Update User Collection
						</Button>
						<Button
							variant="warning"
							className="admin-buttons"
							onClick={(e) => handleSendRegistrationEmail(e)}
						>
							Send Registration Email
						</Button>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel4"}
				onChange={handleChange("panel4")}
				style={{ margin: "10px" }}
			>
				<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
					<Typography
						className="sign-up"
						style={{ fontWeight: "550", fontSize: "16px" }}
					>
						Statistics
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						<Table>
							<thead>
								<tr>
									<td>Name</td>
									<td>Details</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Total Users:</td>
									<td>{totalUsers}</td>
								</tr>
								<tr>
									<td>Total Products:</td>
									<td>{products.length}</td>
								</tr>
								<tr>
									<td>Total Price Uploads:</td>
									<td>{totalPriceUploads}</td>
								</tr>
							</tbody>
						</Table>
					</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
