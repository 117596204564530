import React, { useRef, useState } from "react";
import { TextField, Box } from "@mui/material";
import "../Signup/Signup.scss";
import { Button, Alert } from "react-bootstrap";
import loadingLogo from "../../Images/loading.svg";
import { useNavigate, Link } from "react-router-dom";
// import parthAd from "../../Images/parth_business_card.png";
import { useAuth } from "../../context/AuthContext";
import NavBar from "../NavBar/NavBar";
import "./Login.scss";
export default function Login() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const { login } = useAuth();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (passwordRef.current.value === "" || emailRef.current.value === "") {
			return setError("Please enter email and password.");
		}

		setLoading(true);
		try {
			setError("");
			await login(emailRef.current.value, passwordRef.current.value);
			navigate("/products");
		} catch {
			setError("Failed to Sign in");
		}

		setLoading(false);
	};

	return (
		<div className="signup-page">
			<NavBar />
			<h1 className="signup-heading">Sign in</h1>

			<>
				<Box
					component="form"
					className="form-container"
					sx={{
						"& .MuiTextField-root": { m: 1, width: "25ch" },
					}}
					noValidate
					autoComplete="off"
				>
					{error && <Alert variant="danger">{error}</Alert>}
					<div className="avatar-dropdown">
						<TextField
							required
							variant="filled"
							className="input-text"
							id="filled-email-input"
							color="warning"
							label="Email"
							type="email"
							inputRef={emailRef}
						/>
						<TextField
							required
							className="input-text"
							id="filled-password-input"
							color="warning"
							label="Password"
							type="password"
							autoComplete="current-password"
							variant="filled"
							inputRef={passwordRef}
						/>
					</div>
					{loading ? (
						<img
							src={loadingLogo}
							className="logo"
							style={{
								height: "4rem",
								width: "4rem",
								margin: "auto",
							}}
							alt=""
						/>
					) : (
						<Button className="signup-button" onClick={(e) => handleSubmit(e)}>
							Log In
						</Button>
					)}
					<p className="login-line">
						<Link to="/forgot-password">Forgot password?</Link>
					</p>
				</Box>
				<p className="login-line">
					Need an account? <Link to="/signup">Sign up</Link>
				</p>
			</>

			{/* <div className="ads-container">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/comparify-ca.appspot.com/o/Ads%2FMBGif.gif?alt=media&token=87594720-f603-40d5-a994-6009fe0e3fdd"
          alt="ad-signup"
          className="ads-signup"
        />
        <a href="tel:587-938-3426" className="ads-number">
          {""}
        </a>{" "}
        <p className="ads-message">
          Want to feature here? Contact us{" "}
          <a href="mailto:info@comparify.ca">info@comparify.ca</a>
        </p>
        {/* <iframe
          id="xxx"
          title="xxx"
          width="300px"
          height="100%"
          frameBorder="value"
          allowTransparency
          srcDoc={`
          <!doctype html>
          <html>
          <head>
              <title>Chat bot</title>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width">
          </head>
          <body style="width:100%">
          <script type="text/javascript" src="https://udbaa.com/bnr.php?section=General&pub=666412&format=300x250&ga=g"></script>
<noscript><a href="https://yllix.com/publishers/666412" target="_blank"><img src="//ylx-aff.advertica-cdn.com/pub/300x250.png" style="border:none;margin:0;padding:0;vertical-align:baseline;" alt="ylliX - Online Advertising Network" /></a></noscript>
          </body>
          </html>
          `}
        /> */}
			{/* </div>  */}
		</div>
	);
}
