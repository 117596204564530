import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavBar.scss";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import logo from "../../Images/Logo.png";
import maleAvatar1 from "../../Images/maleAvatar1.png";
import maleAvatar2 from "../../Images/maleAvatar2.png";
import maleAvatar3 from "../../Images/maleAvatar3.png";
import maleAvatar4 from "../../Images/maleAvatar4.png";
import femaleAvatar1 from "../../Images/femaleAvatar1.png";
import femaleAvatar2 from "../../Images/femaleAvatar2.png";
import femaleAvatar3 from "../../Images/femaleAvatar3.png";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
	Badge,
	IconButton,
	Box,
	Typography,
	Toolbar,
	Avatar,
	Menu,
	MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
export default function NavBar(props) {
	const { activeUser, setActiveUser, handleOpenGroceryList, groceryListData } =
		props;
	const { currentUser, logout } = useAuth();
	const [user, setUser] = useState(false);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const navigate = useNavigate();
	useEffect(() => {
		if (currentUser) {
			setUser(true);
		} else {
			setUser(false);
		}
	}, [currentUser, user]);

	const handleLogout = (e) => {
		e.preventDefault();

		try {
			logout();
			setUser(false);
			setActiveUser("");
			navigate("/login");
		} catch (error) {
			alert("Failed to log out", error);
		}
	};

	const renderAvatar = (avatar) => {
		if (avatar === "avatar1") {
			return femaleAvatar1;
		} else if (avatar === "avatar2") {
			return femaleAvatar2;
		} else if (avatar === "avatar3") {
			return femaleAvatar3;
		} else if (avatar === "avatar4") {
			return maleAvatar3;
		} else if (avatar === "avatar5") {
			return maleAvatar1;
		} else if (avatar === "avatar6") {
			return maleAvatar2;
		} else if (avatar === "avatar7") {
			return maleAvatar4;
		}
	};
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};
	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};
	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton sx={{ p: 0, flexGrow: 1 }}>
					<Avatar
						alt={activeUser?.userData?.userName}
						className="avatar"
						src={renderAvatar(activeUser?.userData?.avatar)}
					/>
				</IconButton>
				<Typography
					style={{
						fontSize: "14px",
						color: "#F66B0E",
						fontWeight: "400",
						fontFamily: "Poppins, sans-serif",
					}}
					noWrap
					component="div"
					sx={{ flexGrow: 1, display: { xs: "flex" } }}
				>
					{activeUser?.userData?.userName}
				</Typography>
			</MenuItem>

			<MenuItem onClick={(e) => handleLogout(e)}>
				<IconButton
					size="large"
					aria-label="show 17 new notifications"
					style={{ color: "#F66B0E" }}
				>
					<LogoutIcon />
				</IconButton>
				<p style={{ fontFamily: "Poppins, sans-serif" }}>Logout</p>
			</MenuItem>
		</Menu>
	);

	const renderNavBar = () => {
		return (
			user &&
			activeUser && (
				<div className="avatar-name">
					<Box
						sx={{ flexGrow: 1 }}
						style={{ alignItems: "center", gap: "10px" }}
					>
						<Toolbar
							style={{
								alignItems: "center",
								gap: "10px",
								padding: "0px",
								justifyContent: "flex-end",
								fontFamily: "Poppins, sans-serif",
							}}
						>
							<Box sx={{ display: { xs: "flex" } }}>
								<IconButton
									size="large"
									aria-label="show more"
									aria-controls={mobileMenuId}
									aria-haspopup="true"
									onClick={handleMobileMenuOpen}
									style={{ color: "#F66B0E", fontWeight: "800" }}
								>
									<MenuIcon />
								</IconButton>
							</Box>
						</Toolbar>

						{renderMobileMenu}
					</Box>
				</div>
			)
		);
		//  : (
		// 	<Nav className="nav-links">
		// 		<Nav.Link href="/login" className="company-name login-signup">
		// 			Log In
		// 		</Nav.Link>
		// 		<Nav.Link href="/signup" className="company-name sign-up login-signup">
		// 			Sign up
		// 		</Nav.Link>
		// 	</Nav>
		// );
	};

	return (
		<Navbar sticky="top" expand="lg" bg="dark" variant="dark">
			<Container
				className={currentUser ? "nav-container" : "login-nav-container"}
			>
				<Navbar.Brand href="/" className="company-name">
					<img className="company-logo" src={logo} alt="company-logo" />
					Comparify
				</Navbar.Brand>
				{activeUser && (
					<Button
						className="fav-btn"
						onClick={handleOpenGroceryList}
						disabled={groceryListData?.length < 1}
					>
						<IconButton
							size="small"
							aria-label={`${groceryListData?.reduce(
								(total, store) =>
									total +
									Object.keys(store).reduce(
										(storeTotal, storeName) =>
											storeTotal + store[storeName].length,
										0
									),
								0
							)} items in list`}
							style={{ color: "#112b3c", fontSize: "16px" }}
						>
							<Badge
								badgeContent={groceryListData?.reduce(
									(total, store) =>
										total +
										Object.keys(store).reduce(
											(storeTotal, storeName) =>
												storeTotal + store[storeName].length,
											0
										),
									0
								)}
								overlap="circular"
								color="warning"
							>
								<ListAltIcon style={{ fontSize: "32px" }} />
							</Badge>
						</IconButton>
					</Button>
				)}
				{renderNavBar()}
			</Container>
		</Navbar>
	);
}
