import React, { useRef, useState } from "react";
import { TextField, Box } from "@mui/material";
import "../Signup/Signup.scss";
import { Button, Alert } from "react-bootstrap";
import loadingLogo from "../../Images/loading.svg";
import { Link } from "react-router-dom";
import parthAd from "../../Images/parth_business_card.png";
import { useAuth } from "../../context/AuthContext";
import NavBar from "../NavBar/NavBar";

export default function ForgotPassword() {
  const emailRef = useRef();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailRef.current.value === "") {
      return setError("Please enter email and password.");
    }

    setLoading(true);
    try {
      setError("");
      setMessage("");
      await resetPassword(emailRef.current.value);
      setMessage("Check your email for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  };

  return (
    <div className="signup-page">
      <NavBar />
      <h1 className="signup-heading">Reset Password</h1>

      <>
        <Box
          component="form"
          className="form-container"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <div className="avatar-dropdown">
            <TextField
              required
              variant="filled"
              className="input-text"
              id="filled-email-input"
              color="warning"
              label="Email"
              type="email"
              inputRef={emailRef}
            />
          </div>
          {loading ? (
            <img
              src={loadingLogo}
              className="logo"
              style={{
                height: "4rem",
                width: "4rem",
                margin: "auto",
              }}
              alt=""
            />
          ) : (
            <Button
              className="signup-button"
              onClick={(e) => handleSubmit(e)}
              disabled={message}
            >
              Reset
            </Button>
          )}
        </Box>
        <p className="login-line">
          <Link to="/login"> Log in</Link>
        </p>
        <p className="login-line">
          Need an account? <Link to="/signup">Sign up</Link>
        </p>
      </>

      <div className="ads-container">
        <img src={parthAd} alt="ad-signup" className="ads-signup" />
        <p className="ads-message">Want to feature here? Contact us.</p>
      </div>
    </div>
  );
}
