import React, { useState, useRef } from "react";
import NavBar from "../NavBar/NavBar";
import CardItem from "./CardItem";
import DhruvAd from "../../Images/dhruv_bc.png";
import REGif from "../../Images/Minimalist Corporate Business Card.gif";
// import JHGif from "../../Images/JHomes.gif";
import { Button } from "react-bootstrap";
import UpdatePriceModal from "./UpdatePriceModal";
import CategorySelector from "./CategorySelector";
import { Badge, IconButton, MenuItem } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroceryListModal from "../Modals/GroceryListModal";
import AddProductModal from "./AddProductModal";

export default function ClientHome(props) {
	const { activeUser, setActiveUser, productData } = props;
	const [search, setSearch] = useState("");
	const searchRef = useRef("");
	const [categoryFilter, setCategoryFilter] = useState("");

	const [open, setOpen] = useState(false);
	const [openAddProductModal, setOpenAddProductModal] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [groceryListData, setGroceryListData] = useState([]);
	const handleInputOnChange = (e) => {
		setSearch(e.target.value);
	};

	const [openGroceryList, setOpenGroceryList] = useState(false);
	const handleOpenGroceryList = () => {
		setOpenGroceryList(true);
		// setMobileMoreAnchorEl(null);
	};
	const handleCloseGroceryList = () => {
		setOpenGroceryList(false);
	};

	// function to open AddProductModal
	const handleOpenAddProductModal = () => {
		setOpenAddProductModal(true);
	};

	// function to close AddProductModal
	const handleCloseAddProductModal = () => {
		setOpenAddProductModal(false);
	};

	// Table data must be an array of objects
	const productList = [];

	// const generateAdMap = (key) => {
	//   if (key === 2) {
	//     return <img alt="ad-img" className="ads-home" src={REGif} />;
	//   } else if (key === 4) {
	//     return (
	//       <div style={{ position: "relative" }}>
	//         <img
	//           alt="ad-img"
	//           className="ads-home"
	//           src="https://firebasestorage.googleapis.com/v0/b/comparify-ca.appspot.com/o/Ads%2FGSGif.gif?alt=media&token=d1459961-a550-4c8b-b98f-411c2c250049"
	//         />
	//         <a href="tel:1-647-588-2683" className="ads-number">
	//           {""}
	//         </a>{" "}
	//       </div>
	//     );
	//   } else if (key === 6) {
	//     return <img alt="ad-img" className="ads-home" src={DhruvAd} />;
	//   } else {
	//     return (
	//       <iframe
	//         id="display-ads"
	//         title="ads"
	//         width="300px"
	//         height="50px"
	//         frameBorder="value"
	//         allowTransparency
	//         srcDoc={`
	//         <!doctype html>
	//         <html>
	//         <head>
	//             <title>Chat bot</title>
	//             <meta charset="utf-8">
	//             <meta name="viewport" content="width=device-width">
	//         </head>
	//         <body style="width:100%">
	//           <script type="text/javascript" src="https://udbaa.com/slider.php?section=General&pub=666412&ga=g&side=random"></script>
	//         </body>
	//         </html>
	//         `}
	//       />
	//     );
	//   }
	// };

	if (productData) {
		productData
			.sort(function (a, b) {
				const textA = a.category.toUpperCase();
				const textB = b.category.toUpperCase();

				// First, compare product categories
				if (textA < textB) return -1;
				if (textA > textB) return 1;

				// If categories are the same, compare product names
				const nameA = a.productName.toUpperCase();
				const nameB = b.productName.toUpperCase();

				if (nameA < nameB) return -1;
				if (nameA > nameB) return 1;

				return 0; // Products are equal
			})
			.map((product) => {
				if (
					!product.category
						.toLowerCase()
						.includes(categoryFilter.trim().toLowerCase()) ||
					!product.productName
						.toLowerCase()
						.includes(search.trim().toLowerCase())
				) {
					return true;
				} else {
					const productName = product.productName;
					const productURL = product.productURL;
					const stores = product.stores;
					const productDetails = product;
					const label = product.productName;
					const productId = product.productId;
					productList.push({
						productName,
						productURL,
						stores,
						productDetails,
						label,
						productId,
					});
				}
			});
	}

	let products = [];

	productData.map((data, key) =>
		products.push({ label: data.productName, productId: data.productId })
	);

	return (
		<div className="signup-page">
			<NavBar
				activeUser={activeUser}
				setActiveUser={setActiveUser}
				groceryListData={groceryListData}
				handleOpenGroceryList={handleOpenGroceryList}
			/>

			<div className="client-container">
				<GroceryListModal
					handleClose={handleCloseGroceryList}
					openGroceryList={openGroceryList}
					groceryListData={groceryListData}
				/>
				<input
					className="search-bar"
					placeholder="Search product"
					type="text"
					ref={searchRef}
					onChange={(e) => handleInputOnChange(e)}
					variant="filled"
					style={{ margin: "5px auto" }}
					// inputRef={searchRef}
				/>

				<CategorySelector
					categoryFilter={categoryFilter}
					setCategoryFilter={setCategoryFilter}
				/>
				<div className="disclaimer">
					<b>Disclaimer:</b> The prices displayed herein are submitted by our
					users. Neither we nor the listed stores assume responsibility for the
					accuracy or reliability of these reported prices. The listed stores
					are prohibited from participating in any form of price matching
					through comparify.ca. The provided prices are solely for reference
					purposes.
				</div>

				<div>
					<div className="card-footer">
						<Button
							className="action-btn"
							onClick={handleOpen}
							productDetails={productData}
						>
							Report price
						</Button>
						<Button
							className="action-btn"
							onClick={handleOpenAddProductModal}
							productDetails={productData}
						>
							Add product
						</Button>
					</div>

					<UpdatePriceModal
						open={open}
						handleClose={handleClose}
						productDetails={productList}
						user={activeUser}
					/>
					<AddProductModal
						open={openAddProductModal}
						handleClose={handleCloseAddProductModal}
						user={activeUser}
					/>
					{productList.map((data, key) => (
						<section key={key}>
							{/* {key !== 0 && generateAdMap(key)} */}
							<CardItem
								key={key}
								productName={data.productName}
								imgUrl={data.productURL}
								stores={data.stores}
								data={data.productDetails}
								user={activeUser}
								groceryListData={groceryListData}
								setGroceryListData={setGroceryListData}
							/>
						</section>
					))}
				</div>
			</div>
		</div>
	);
}
