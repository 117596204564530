import React, { useRef, useState } from "react";
import { MenuItem, TextField, FormControl, Select, Box } from "@mui/material";
import "./Signup.scss";
import { Button, Alert, Form } from "react-bootstrap";
import loadingLogo from "../../Images/loading.svg";
import defaultAvatar from "../../Images/defaultAvatar.png";
import maleAvatar1 from "../../Images/maleAvatar1.png";
import maleAvatar2 from "../../Images/maleAvatar2.png";
import maleAvatar3 from "../../Images/maleAvatar3.png";
import maleAvatar4 from "../../Images/maleAvatar4.png";
import femaleAvatar1 from "../../Images/femaleAvatar1.png";
import femaleAvatar2 from "../../Images/femaleAvatar2.png";
import femaleAvatar3 from "../../Images/femaleAvatar3.png";
import dhruvAd from "../../Images/dhruv_bc.png";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import { createUser } from "../helpers/source";
import axios from "axios";
import TermsModal from "../Modals/TermsModal";
// import { db } from "../../firebase";

export default function Signup() {
	const [avatar, setAvatar] = useState("");
	const emailRef = useRef();
	const userNameRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const [agreeToMarketing, setAgreeToMarketing] = useState(false);
	const [agreeToTerms, setAgreeToTerms] = useState(false);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [showTermsModal, setShowTermsModal] = useState(false);
	const { signup } = useAuth();
	const navigate = useNavigate();

	const handleInputChange = (e) => {
		setAvatar(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!avatar) {
			return setError("Please select an avatar");
		}

		if (userNameRef.current.value.length > 7) {
			return setError("Username must be max. 7 characters");
		}
		if (!emailRef || !passwordRef || !confirmPasswordRef || !agreeToTerms) {
			return setError("Please fill out all required info.");
		}
		if (passwordRef.current.value !== confirmPasswordRef.current.value) {
			return setError("Passwords do not match");
		}

		setLoading(true);
		let userData = {
			avatar: avatar,
			userName: userNameRef.current.value,
			userEmail: emailRef.current.value,
			agreeToTerms: agreeToTerms,
			agreeToMarketing: agreeToMarketing,
		};

		try {
			setError("");

			const user = await signup(
				emailRef.current.value,
				passwordRef.current.value
			);
			await createUser(user.user.uid, userData);
			navigate("/products");
			const baseUrl =
				"https://northamerica-northeast1-ca-comparify.cloudfunctions.net/api";
			// "http://localhost:5000/ca-comparify/northamerica-northeast1/api";
			const url = `${baseUrl}/api/registration-email/`;
			await axios.post(url, userData);
		} catch (error) {
			setError("Sign up failed. Please try again", error.FirebaseError);
			return false;
		}

		setLoading(false);
	};

	return (
		<div className="signup-page">
			<TermsModal
				showTermsModal={showTermsModal}
				setShowTermsModal={setShowTermsModal}
			/>
			<NavBar />
			<h1 className="signup-heading">Let's get you started!</h1>
			{loading ? (
				<img
					src={loadingLogo}
					className="logo"
					style={{
						height: "20rem",
						width: "20rem",
						margin: "0 15px",
						textAlign: "center",
					}}
					alt=""
				/>
			) : (
				<>
					<Box
						component="form"
						className="form-container"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						noValidate
						autoComplete="off"
					>
						<div className="mb-3 avatar-dropdown avatar-selector">
							<p>Select your Avatar</p>
							<FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
								<Select
									labelId="demo-simple-select-filled-label"
									id="demo-simple-select-filled"
									value={avatar}
									displayEmpty
									onChange={(e) => handleInputChange(e)}
								>
									<MenuItem value="" defaultChecked disabled>
										<img
											src={defaultAvatar}
											alt={`avatar-default`}
											className="signup-avatar"
										/>
									</MenuItem>
									{[
										femaleAvatar1,
										femaleAvatar2,
										femaleAvatar3,
										maleAvatar3,
										maleAvatar1,
										maleAvatar2,
										maleAvatar4,
									].map((avatar, key) => (
										<MenuItem key={key} value={`avatar${key + 1}`}>
											<img
												src={avatar}
												alt={`avatar${key}`}
												className="signup-avatar"
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
						<div className="avatar-dropdown">
							<TextField
								required
								className="input-text"
								id="filled-username-input"
								color="warning"
								label="Username"
								helperText="max. 7 characters"
								type="text"
								variant="filled"
								inputRef={userNameRef}
							/>
							<TextField
								required
								variant="filled"
								className="input-text"
								id="filled-email-input"
								color="warning"
								label="Email"
								type="email"
								inputRef={emailRef}
							/>
							<TextField
								required
								className="input-text"
								id="filled-password-input"
								color="warning"
								label="Password"
								type="password"
								autoComplete="current-password"
								variant="filled"
								inputRef={passwordRef}
							/>
							<TextField
								required
								className="input-text"
								id="filled-confirm-password-input"
								color="warning"
								label="Confirm Password"
								type="password"
								autoComplete="current-password"
								variant="filled"
								inputRef={confirmPasswordRef}
							/>

							<Form.Check
								type="checkbox"
								required
								onChange={(e) => setAgreeToTerms(e.target.checked)}
								className="checkbox-text"
								id="default-checkbox"
								label={
									<span>
										I agree to all{" "}
										<p
											style={{
												color: "blue",
												textDecoration: "underline",
												display: "inline",
												cursor: "pointer",
											}}
											onClick={() => setShowTermsModal(true)}
										>
											Terms and Conditions *
										</p>
									</span>
								}
							/>

							<Form.Check
								type={"checkbox"}
								onChange={(e) => setAgreeToMarketing(e.target.checked)}
								className="checkbox-text"
								label={`I agree to receive marketing and promotional emails`}
								id={`default-checkbox`}
							/>
						</div>
						{error && <Alert variant="danger">{error}</Alert>}
						{loading ? (
							<img
								src={loading}
								className="logo"
								style={{
									height: "2rem",
									width: "2rem",
									margin: "0 5px",
								}}
								alt=""
							/>
						) : (
							<Button
								className="signup-button"
								onClick={(e) => handleSubmit(e)}
							>
								Sign up
							</Button>
						)}
					</Box>
					<p className="login-line">
						Already have an account? <a href="/login">Log In</a>
					</p>
				</>
			)}
			{/* <div className="ads-container">
        <img src={dhruvAd} alt="ad-signup" className="ads-signup" />
        <p className="ads-message">
          Want to feature here? Contact us{" "}
          <a href="mailto:info@comparify.ca">info@comparify.ca</a>.
        </p>
      </div> */}
		</div>
	);
}
