import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import categoryOptions from "../helpers/categoryOptions";

export default function CategorySelector(props) {
  const { categoryFilter, setCategoryFilter } = props;

  const handleChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  return (
    <div>
      <FormControl variant="filled" className="search-bar" color="warning">
        <InputLabel id="demo-simple-select-filled-label">
          Select Category
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={categoryFilter}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {categoryOptions.map((category, key) => (
            <MenuItem key={key} value={category.value}>
              {category?.value.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
