// // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyD1xeWi1R_r1dB7d9HVnlUlyPGblRp4K4E",
//   authDomain: "comparify-ca.firebaseapp.com",
//   databaseURL: "https://comparify-ca-default-rtdb.firebaseio.com",
//   projectId: "comparify-ca",
//   storageBucket: "comparify-ca.appspot.com",
//   messagingSenderId: "47167894968",
//   appId: "1:47167894968:web:283aa0d0c802c6818c4b4d",
//   measurementId: "G-0C6RQFRV5H",
// };

// ****** New CA-COMPARIFY config below

// // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD6sOrjvn21Ws-XWVQ0XOt5uFZmsbStFhA",
  authDomain: "ca-comparify.firebaseapp.com",
  projectId: "ca-comparify",
  storageBucket: "ca-comparify.appspot.com",
  messagingSenderId: "501940532033",
  appId: "1:501940532033:web:04906ef60bf5de3115a7c0",
  measurementId: "G-4XXFVE8HSF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
// const analytics = getAnalytics(app);
export default app;
