import React from "react";
import Table from "@mui/material/Table";
import { voteCorrectPrice } from "../helpers/source";
import moment from "moment";
import { Form } from "react-bootstrap";

export default function StoreTable(props) {
	const {
		stores,
		productData,
		user,
		productName,
		groceryListData,
		setGroceryListData,
	} = props;

	const calculateWidth = (votes, totalVotes) => {
		const votesPercent = (votes / totalVotes) * 100;
		return votesPercent;
	};

	//Processes firebase timestamp format if date is from database, otherwise process as regular timestamp to be displayed
	const processDate = (date) => {
		return date && date.seconds
			? moment(date.seconds * 1000).format()
			: date
			? moment(date).format()
			: "_____ /_____ /________";
	};

	const calculateLastTimeVote = (timestamp) => {
		return moment(processDate(timestamp)).fromNow();
	};

	const handleVoteCorrectPrice = async (e, key, type) => {
		e.preventDefault();
		await voteCorrectPrice(type, productData.productId, key, user.userId);
	};

	const addToList = (e, row, productName) => {
		const storeName = row.storeName;

		setGroceryListData((current) => {
			const updatedData = [...current];

			if (e.target.checked === true) {
				// Check if the store already exists in the data
				const storeIndex = updatedData.findIndex(
					(item) => Object.keys(item)[0] === storeName
				);

				if (storeIndex !== -1) {
					// Store exists, check if the product is already in the store's list
					if (!updatedData[storeIndex][storeName].includes(productName)) {
						updatedData[storeIndex][storeName].push(productName);
					}
				} else {
					// Store doesn't exist, create a new store entry with the product
					updatedData.push({ [storeName]: [productName] });
				}
			} else {
				// Find the store and remove the product from its list
				const storeIndex = updatedData.findIndex(
					(item) => Object.keys(item)[0] === storeName
				);

				if (storeIndex !== -1) {
					updatedData[storeIndex][storeName] = updatedData[storeIndex][
						storeName
					].filter((product) => product !== productName);

					// Remove the store entry if its list is empty
					if (updatedData[storeIndex][storeName].length === 0) {
						updatedData.splice(storeIndex, 1);
					}
				}
			}

			return updatedData;
		});
	};

	return (
		<Table aria-label="simple table">
			<tbody
				style={{
					padding: "5px",
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: "5px",
					alignItems: "center",
				}}
			>
				{stores.map((row, key) => (
					<section className="item-container" key={key}>
						<tr className="item-row">
							<td align="left" className="store-name">
								<a
									className="store-link"
									href={row.mapLink}
									target="_blank"
									rel="noreferrer"
								>
									{row.storeName}
								</a>
							</td>
							{row.currentPrice !== "n/a" && (
								<td align="left" className="store-price">
									{row.currentPrice === "n/a" ? "-" : `$${row.currentPrice}`}
								</td>
							)}
						</tr>

						{row.currentPrice !== "n/a" ? (
							<>
								<section className="meter-container">
									<button
										className="thumbs-up-down"
										onClick={(e) => handleVoteCorrectPrice(e, key, "minus")}
									>
										<i
											class="fa-solid fa-thumbs-down"
											hidden={row?.userVotes?.includes(user.userId)}
										></i>
									</button>
									<section className="meter">
										<p className="total-votes">
											{(row?.correctPriceVotes || row?.wrongPriceVotes) &&
												calculateWidth(
													row?.correctPriceVotes,
													row?.correctPriceVotes + row?.wrongPriceVotes
												)}
											%&nbsp;(
											{row?.correctPriceVotes + row?.wrongPriceVotes}{" "}
											{row?.correctPriceVotes + row?.wrongPriceVotes > 1
												? "votes"
												: "vote"}
											)
										</p>
										<span
											style={{
												width: `${calculateWidth(
													row?.wrongPriceVotes,
													row?.correctPriceVotes + row?.wrongPriceVotes
												)}%`,
												backgroundColor:
													row?.correctPriceVotes + row?.wrongPriceVotes === 0 &&
													"#d2d2d2",
											}}
										></span>
										<p className="last-update">
											last reported {calculateLastTimeVote(row.lastVoteTime)}
										</p>
									</section>
									<button
										className="thumbs-up-down"
										onClick={(e) => handleVoteCorrectPrice(e, key, "add")}
										disabled={row?.userVotes?.includes(user.userId)}
									>
										<i
											class="fa-solid fa-thumbs-up"
											hidden={row?.userVotes?.includes(user.userId)}
										></i>
									</button>
								</section>
								<Form.Check
									type="switch"
									id={`custom-switch-${key}`}
									label="Add to List"
									className="grocery-list-switch"
									onClick={(e) => addToList(e, row, productName)}
								/>
							</>
						) : (
							<p style={{ fontSize: "12px" }}>Price not reported</p>
						)}
					</section>
				))}
			</tbody>
		</Table>
	);
}
