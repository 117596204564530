import React, { useLayoutEffect, useEffect, useState } from "react";
import { db } from "../../firebase";

import loading from "../../Images/loading.svg";
// import pacman from "../../Images/pacman.svg";
import { getAuth } from "firebase/auth";
import "./Home.scss";
import AdminHome from "./AdminHome";
import ClientHome from "./ClientHome";
import { doc, collection, onSnapshot } from "firebase/firestore";
export default function Home() {
  const { currentUser } = getAuth();
  const [activeUser, setActiveUser] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [productData, setProductData] = useState([]);

  useLayoutEffect(() => {
    setUserLoading(true);
    onSnapshot(doc(db, "users", currentUser?.uid), (doc) => {
      setActiveUser(doc.data());
    });
    setTimeout(() => {
      setUserLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const productRef = collection(db, "products");
    const unsubscribe = onSnapshot(productRef, (snapshot) => {
      let productsArr = [];
      snapshot.forEach((doc) => {
        productsArr.push(doc.data());
      });
      setProductData(() => productsArr);
    });
  }, []);

  // USE IT WHEN YOU WANT TO TAKE THE APP DOWN FOR MAINTENANCE

  // const renderHomePage = () => {
  //   console.log("function called", activeUser);
  //   if (!activeUser.admin) {
  //     <div className="signup-page">
  //       <NavBar activeUser={activeUser} setActiveUser={setActiveUser} />
  //       <div className="home-container">
  //         <h1 className="thank-you">
  //           Thank you for early bird registration! <br /> Exciting platform is
  //           in builts.{" "}
  //           <img
  //             src={pacman}
  //             className="logo"
  //             style={{
  //               height: "4rem",
  //               width: "4rem",
  //               // margin: "0 15px",
  //             }}
  //             alt=""
  //           />
  //         </h1>
  //         <div className="home-advertise">
  //           <img
  //             src={dummyRealtor}
  //             alt="home-ad"
  //             className="home-advertise-image"
  //           />
  //           <p className="ads-message">Want to feature here? Contact us.</p>
  //         </div>
  //       </div>
  //     </div>;
  //   } else if (activeUser.admin) {
  //     <AdminHome activeUser={activeUser} setActiveUser={setActiveUser} />;
  //   }
  // };

  return (
    <div className="home-wrapper">
      {userLoading ? (
        <img
          src={loading}
          className="logo"
          style={{
            height: "15rem",
            width: "100%",
            margin: "10px auto",
          }}
          alt=""
        />
      ) : !activeUser.admin ? (
        //       <img
        //         src={pacman}
        //         className="logo"
        //         style={{
        //           height: "4rem",
        //           width: "4rem",
        //           // margin: "0 15px",
        //         }}
        //         alt=""
        //       />

        <ClientHome
          activeUser={activeUser}
          setActiveUser={setActiveUser}
          productData={productData}
        />
      ) : (
        <AdminHome
          activeUser={activeUser}
          setActiveUser={setActiveUser}
          productData={productData}
        />
      )}
    </div>
  );
}
