import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import loadingLogo from "../../Images/loading.svg";
import { Alert } from "react-bootstrap";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
export default function AddProduct(props) {
	const { user } = props;
	const [productName, setProductName] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);

	const handleProductNameChange = (event) => {
		setProductName(event.target.value);
	};

	const handleAddProduct = async () => {
		// Handle adding the product using the productName and productPrice
		setLoading(true);
		if (productName === "" || productName.length < 6) {
			setError("Please enter product name");
			setLoading(false);
			return;
		}

		const baseUrl =
			"https://northamerica-northeast1-ca-comparify.cloudfunctions.net/api";
		// "http://localhost:5000/ca-comparify/northamerica-northeast1/api";

		const data = {
			productName: productName,
			userEmail: user.userData.userEmail,
			userName: user.userData.userName,
		};
		await axios.post(`${baseUrl}/api/add-product-email/`, data);
		setSuccess(true);
		setError("Product added for review. Thank you 🙏");
		setLoading(false);
		// Reset input fields
		setProductName("");
	};

	useEffect(() => {
		if (error && success) {
			setTimeout(() => {
				setError("");
				setSuccess(false);
			}, 3000);
		}
	}, [error, success]);

	return (
		<div>
			<TextField
				label="Enter Product Name"
				variant="outlined"
				fullWidth
				value={productName}
				onChange={handleProductNameChange}
				margin="normal"
			/>
			<div
				style={{
					fontSize: "12px",
					color: "grey",
					width: "max-content",
					margin: "5px auto",
					display: "flex",
					alignItems: "center",
				}}
			>
				<InfoIcon style={{ height: "14px", width: "14px", margin: "5px 0" }} />
				<p style={{ marginLeft: "3px" }}>ex: BRAND NAME product name weight</p>
			</div>
			{error && <Alert variant={success ? "success" : "danger"}>{error}</Alert>}
			<div style={{ width: "max-content", margin: "15px auto" }}>
				{loading ? (
					<img
						src={loadingLogo}
						className="logo"
						style={{
							height: "2rem",
							width: "2rem",
							margin: "0 5px",
						}}
						alt=""
					/>
				) : (
					<Button className="btn-primary" onClick={(e) => handleAddProduct(e)}>
						Submit
					</Button>
				)}
			</div>
		</div>
	);
}
