import React from "react";
import "./Footer.scss";
export default function Footer() {
	return (
		<div className="footer-container">
			<section className="store-contact lp-container">
				<h2 className="lp-heading-2">Open to dynamic partnerships! 🚀</h2>
				<p className="lp-description">
					Contact us at &nbsp;
					<span className="email-highlight">info@comparify.ca</span>
					<br /> Let's elevate together!
				</p>
				{/* <Button className="lp-button">Contact us</Button> */}
			</section>
			<p className="ft-copyright">Copyright 2023©</p>
		</div>
	);
}
