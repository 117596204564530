import React from "react";
import { Modal } from "react-bootstrap";

export default function TermsModal(props) {
	const { showTermsModal, setShowTermsModal } = props;

	const handleClose = () => {
		setShowTermsModal(false);
	};
	return (
		<Modal show={showTermsModal} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Terms and Conditions of Use for comparify.ca</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				These Terms and Conditions("Terms") govern your use of the comparify.ca
				website ("Website"), which provides a platform for comparing prices of
				various products from different stores. By accessing and using this
				Website, you agree to be bound by these Terms. If you do not agree with
				these Terms, please refrain from using the Website. <br /> <br />
				<b>User Conduct:</b> You agree to use the Website for lawful purposes
				only. You are responsible for any content you post or submit through the
				Website. You will not engage in any activity that may disrupt or
				compromise the Website's functionality. <br /> <br />
				<b>Information Accuracy:</b> Prices, product details, and store
				information provided on the Website are user-contributed and for
				reference purposes only. We do not guarantee the accuracy, completeness,
				or reliability of the information presented. Use your discretion and
				verify information with the respective stores before making purchasing
				decisions. <br /> <br />
				<b>Intellectual Property:</b> The content on the Website, including
				text, graphics, logos, and images, is protected by copyright and other
				intellectual property laws. You may not reproduce, distribute, modify,
				or create derivative works based on this content without proper
				authorization. <br />
				<br />
				<b>Third-Party Links:</b> The Website may contain links to third-party
				websites. We do not endorse or take responsibility for the content,
				products, or services offered on these external sites. <br />
				<br />
				<b>Limitation of Liability:</b> We are not liable for any direct,
				indirect, incidental, consequential, or punitive damages arising from
				your use of the Website. We do not endorse, warrant, or guarantee any
				products, services, or offers presented on the Website. <br />
				<br />
				<b>Changes and Termination: </b>
				We reserve the right to modify, suspend, or terminate the Website at any
				time without notice. We may update these Terms from time to time, and
				your continued use constitutes acceptance of the revised Terms. <br />
				<br />
				<b>Privacy:</b>
				Your privacy is important to us. Please review our Privacy Policy to
				understand how we collect, use, and protect your personal information.{" "}
				<br />
				<br />
				<b>Governing Law:</b> These Terms are governed by and construed in
				accordance with the laws of Alberta, CA. Any disputes arising from or
				relating to these Terms shall be subject to the exclusive jurisdiction
				of the courts in Alberta, CA. <br />
				<br />
				By using comparify.ca, you acknowledge that you have read, understood,
				and agreed to these Terms and Conditions. If you have any questions or
				concerns, please contact us at info@comparify.ca.
			</Modal.Body>
		</Modal>
	);
}
