import { TableBody, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";

export default function GroceryList(props) {
	const { groceryLists, buildProductList } = props;

	// Manage the strikeThrough state for each product individually

	// Filter out stores with empty product lists
	const storesWithProducts = Object.entries(groceryLists).filter(
		([storeName, products]) => products.length > 0
	);

	return storesWithProducts.map(([storeName, products], storeIndex) => (
		<React.Fragment key={storeIndex}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead sx={{ backgroundColor: "yellow" }}>
					<TableRow>{storeName}</TableRow>
				</TableHead>
				<TableBody>{buildProductList(storeName, products)}</TableBody>
			</Table>
		</React.Fragment>
	));
}
