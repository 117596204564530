import React from "react";
import { Button } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import TextLoop from "react-text-loop";
import HandshakeIcon from "@mui/icons-material/Handshake";
import "./LandingPage.scss";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
export default function LandingPage() {
	return (
		<div className="landing-page">
			<NavBar />
			<section className="lp-container">
				<h1 className="lp-heading">
					Start your{" "}
					<TextLoop noWrap>
						<span className="textloop">saving</span>
						<span className="textloop">comparify</span>
					</TextLoop>
					<br /> journey now.
				</h1>

				<p className="lp-description">
					Comparify empowers smart shopping decisions by comparing grocery
					prices across diverse stores. Our platform enables customers to make
					informed choices, ensuring they get the best value for their money
					while saving time and effort. We prioritize convenience,
					affordability, and quality, transforming shopping into a seamless,
					economical experience.
				</p>
				<div className="lp-auth-btns">
					<Button className="lp-button" href="/signup">
						Sign up
					</Button>
					<Button className="lp-button" href="/login">
						Log in
					</Button>
				</div>
			</section>

			<Footer />
		</div>
	);
}
