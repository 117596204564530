import React from "react";
import UpdatePrice from "./UpdatePrice";
import { Button, Modal } from "react-bootstrap";
import AddProduct from "./AddProduct";

export default function AddProductModal(props) {
	const { open, handleClose, productDetails, user } = props;

	return (
		<div>
			<Modal show={open} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Add a product</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddProduct user={user} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
