import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import StoreTable from "./StoreTable";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function CardItem(props) {
	const {
		productName,
		imgUrl,
		stores,
		data,
		user,
		groceryListData,
		setGroceryListData,
	} = props;

	return (
		<Card sx={{ maxWidth: 345 }} className="card-container">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					backgroundColor: "#efefef",
					borderBottom: "1px solid #555",
					width: "100%",
				}}
			>
				<Zoom>
					<img
						component="img"
						className="card-img"
						alt="product-img"
						height="80"
						src={imgUrl}
					/>
				</Zoom>

				<p className="card-header">{productName}</p>
			</div>

			<CardContent
				style={{
					padding: "5px 0",
				}}
			>
				<StoreTable
					stores={stores}
					productData={data}
					user={user}
					productName={productName}
					groceryListData={groceryListData}
					setGroceryListData={setGroceryListData}
				/>
			</CardContent>
		</Card>
	);
}
