import React from "react";
import UpdatePrice from "./UpdatePrice";
import { Button, Modal } from "react-bootstrap";

export default function UpdatePriceModal(props) {
  const { open, handleClose, productDetails, user } = props;
  // const handleUploadPrice = (file, fileName) => {
  //   const storageRef = storage.ref("/Prices");

  //   const fileRef = storageRef?.child(fileName);

  //   const newMetadata = {
  //     contentType: "image/jpg",
  //     customMetadata: {
  //       storeName:
  //     },
  //   };

  //   let image = file.split(",")[1];

  //   storageRef
  //     .child(fileName)
  //     .putString(image, "base64")
  //     .then(() => {
  //       fileRef.updateMetadata(newMetadata);
  //       console.log("successfully uploaded");
  //     })
  //     .catch((err) => {
  //       console.log("error in uploading");
  //     });
  // }
  return (
    <div>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdatePrice products={productDetails} user={user} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
