export default [
  {
    label: "All India",
    value: "All India",
  },
  {
    label: "Bombay Spices",
    value: "Bombay Spices",
  },
  {
    label: "Chalo Freshco",
    value: "Chalo Freshco",
  },
  {
    label: "Royal India SM",
    value: "Royal India SM",
  },
  {
    label: "Superstore",
    value: "Superstore",
  },
  {
    label: "Walmart",
    value: "Walmart",
  },
];
