export default [
  {
    label: "Dairy",
    value: "dairy",
  },
  {
    label: "Flours",
    value: "flours",
  },
  {
    label: "Frozen",
    value: "frozen",
  },

  {
    label: "Pantry",
    value: "pantry",
  },
  {
    label: "Rice",
    value: "rice",
  },
  {
    label: "Snacks",
    value: "snacks",
  },
];
