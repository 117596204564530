import React, { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Button, Modal, Table } from "react-bootstrap";
import GroceryList from "./GroceryList";
import "./GroceryList.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Paper, TableCell, TableContainer, TableRow } from "@mui/material";

export default function GroceryListModal(props) {
	const { openGroceryList, handleClose, groceryListData } = props;
	const [copied, setCopied] = useState(false);
	const [groceryLists, setGroceryLists] = useState({});
	const [strikeThroughList, setStrikeThroughList] = useState({});

	useEffect(() => {
		// Build grocery lists based on groceryListData
		const lists = buildGroceryList();
		setGroceryLists(lists);
	}, [groceryListData]);

	const buildGroceryList = () => {
		const groceryLists = {};

		// Initialize grocery lists for each store
		const storeNames = [
			"All India",
			"Walmart",
			"Bombay Spices",
			"Royal India SM",
			"Superstore",
			"Chalo Freshco",
		];

		storeNames.forEach((storeName) => {
			groceryLists[storeName] = [];
		});

		// Populate grocery lists based on groceryListData
		groceryListData.forEach((data, key) => {
			const productName = Object.values(data)[0];
			const storeName = Object.keys(data)[0];

			if (groceryLists.hasOwnProperty(storeName)) {
				// Check if the product is already in the store's list
				if (!groceryLists[storeName].includes(productName)) {
					groceryLists[storeName].push(productName);
				}
			}
		});

		return groceryLists;
	};

	const buildProductList = (storeName, productNames) => {
		return productNames.map((productList, productIndex) => {
			return (
				<React.Fragment key={productIndex}>
					{productList.map((productName, productNameIndex) => (
						<TableRow
							key={productNameIndex}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							style={{
								marginLeft: "10px",
								display: "flex",
								alignItems: "center",
								fontSize: "13px",
							}}
						>
							<TableCell component="th" scope="row">
								<ArrowRightIcon
									style={{
										height: "16px",
										width: "16px",
										// color: "rgba(255, 102, 0, 0.62)",
									}}
								/>
								<span
									style={{
										textDecoration:
											strikeThroughList[storeName] &&
											strikeThroughList[storeName][productName]
												? "line-through"
												: "none",
										color:
											strikeThroughList[storeName] &&
											strikeThroughList[storeName][productName]
												? "lightgray"
												: "initial",
										marginLeft: "9px",
									}}
								>
									{productName}
								</span>
							</TableCell>

							{/* Add product price cell */}
						</TableRow>

						/* <td>
                <Form.Check
                  type="checkbox"
                  onClick={() => handleCheckOff(storeName, productName)}
                />
              </td> */
					))}
				</React.Fragment>
			);
		});
	};

	const renderGroceryList = () => {
		return (
			<Table>
				<tbody>
					{Object.entries(groceryLists).map(
						([storeName, products], storeIndex) => (
							<React.Fragment key={storeIndex}>
								<tr>
									<td colSpan="2">
										<strong>{storeName}</strong>
									</td>
								</tr>
								{buildProductList(storeName, products)}
							</React.Fragment>
						)
					)}
				</tbody>
			</Table>
		);
	};

	// Convert the JSX elements to an HTML string
	const getPlainTextRepresentation = () => {
		let plainText = "";
		Object.entries(groceryLists).forEach(([storeName, products]) => {
			plainText += `${storeName}:\n`;
			products.forEach((productName) => {
				plainText += `  - ${productName}\n`;
			});
		});
		return plainText;
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 3000);
		}
	}, [copied]);

	return (
		<Modal show={openGroceryList} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Grocery List</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TableContainer component={Paper}>
					{Object.entries(groceryLists).map(([storeName, products], index) => (
						<GroceryList
							key={index}
							groceryLists={{ [storeName]: products }}
							buildProductList={buildProductList}
						/>
					))}
				</TableContainer>
			</Modal.Body>
			<Modal.Footer>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<CopyToClipboard text={getPlainTextRepresentation()}>
						<Button variant="primary" onClick={() => setCopied(true)}>
							Copy
						</Button>
					</CopyToClipboard>
					{copied && <p style={{ fontSize: "8px" }}>Copied to clipboard!</p>}
				</div>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
