import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { Button, Alert } from "react-bootstrap";
import storeOptions from "../helpers/storeOptions";
import { updateStorePrice } from "../helpers/source";
import loadingLogo from "../../Images/loading.svg";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import axios from "axios";

export default function UpdatePrice(props) {
	const { products, user } = props;
	const [loading, setLoading] = useState(false);
	const storePriceRef = useRef();

	const [success, setSuccess] = useState(false);

	const [productName, setProductName] = useState();
	const [error, setError] = useState("");
	const [productStore, setProductStore] = useState("");
	const [priceUploadPicture, setPriceUploadPicture] = useState("");

	const handlePriceUploadChange = (e) => {
		setPriceUploadPicture(e.target.files[0]);
	};

	const handlePriceUpload = async () => {
		// Create file metadata including the content type
		const storage = getStorage();
		// create a Prices reference inside storage
		const storageRef = ref(storage, `Prices/${productStore}_${moment()}`);
		const newMetadata = {
			contentType: "image/jpg",
			customMetadata: {
				storeName: productStore,
				userName: user.userData.userName,
				userId: user.userId,
			},
		};

		// Upload the file and metadata
		const uploadTask = uploadBytes(storageRef, priceUploadPicture, newMetadata);
		const userData = {
			userName: user.userData.userName,
		};
		setSuccess(true);
		setError("Price uploaded for review. Thank you 🙏");
		const baseUrl =
			"https://northamerica-northeast1-ca-comparify.cloudfunctions.net/api";
		// "http://localhost:5000/ca-comparify/northamerica-northeast1/api";
		const url = `${baseUrl}/api/new-price-upload-email/`;
		await axios.post(url, userData);

		setProductStore("");
		setPriceUploadPicture("");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (
			user.admin &&
			(!productName || !productStore || storePriceRef.current.value === "")
		) {
			setError("All fields are mandatory.");
			return false;
		} else if (!user.admin && (!productStore || !priceUploadPicture)) {
			setError("Store / Image cannot be blank");
			return false;
		}
		setLoading(true);
		let productId = "";

		products.map((data, key) => {
			if (productName === data.label) {
				productId = data.productId;
			}
		});
		let storeKey;
		storeOptions.map((data, key) => {
			if (productStore === data.label) {
				storeKey = key;
			}
		});

		if (user.admin) {
			try {
				await updateStorePrice(
					productId,
					storeKey,
					Number(storePriceRef.current.value)
				);
				setSuccess(true);
				setError("Price updated. Thank you 🙏");
				setProductStore("");
				setProductName("");
			} catch (error) {
				alert(error);
				setError("Error adding product");
			}
		} else {
			try {
				handlePriceUpload();
			} catch (err) {
				console.log("error in upload", err);
			}
		}

		setTimeout(() => {
			setLoading(false);
		}, 1500);
	};

	const handleAddAnother = (e) => {
		e.preventDefault();
		setError(false);
		setSuccess(false);
	};

	return (
		<div>
			{" "}
			{loading ? (
				<img
					src={loadingLogo}
					className="logo"
					style={{
						height: "10rem",
						width: "100%",
						margin: "10px auto",
					}}
					alt=""
				/>
			) : success ? (
				<Box
					component="form"
					className="form-container"
					style={{ padding: "5% 5% 3% 5%" }}
					sx={{
						"& .MuiTextField-root": { m: 1, width: "25ch" },
					}}
					noValidate
					autoComplete="off"
				>
					<i className="fa-solid fa-circle-check"></i>
					<Alert variant="success">{error}</Alert>
					<Button
						className="signup-button"
						onClick={(e) => handleAddAnother(e)}
					>
						Update another
					</Button>
				</Box>
			) : (
				<Box
					component="form"
					className="form-container"
					sx={{
						"& .MuiTextField-root": { m: 1, width: "25ch" },
					}}
					noValidate
					style={{ padding: "5% 5% 3% 5%" }}
					autoComplete="off"
				>
					<Autocomplete
						id="auto-complete-demo 1"
						value={productStore}
						options={storeOptions.map((option) => option.label)}
						onChange={(event, newValue) => setProductStore(newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select Store"
								color="warning"
								className="input-text"
								InputProps={{
									...params.InputProps,
									type: "search",
								}}
								style={{ margin: "5px auto" }}
							/>
						)}
					/>
					{user.admin && (
						<>
							<Autocomplete
								id="auto-complete-demo 2"
								value={productName}
								options={products?.map((option, key) => option.label)}
								onChange={(event, newValue) => setProductName(newValue)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Select product"
										color="warning"
										className="input-text"
										InputProps={{
											...params.InputProps,
											type: "search",
										}}
										style={{ margin: "5px auto" }}
									/>
								)}
							/>

							<TextField
								required
								id="filled-price-1"
								className="input-text"
								variant="filled"
								color="warning"
								label={`Enter price $`}
								type="number"
								inputRef={storePriceRef}
								style={{ margin: "5px auto" }}
							/>
						</>
					)}
					{!user.admin && (
						<div className="update-price-manual">
							<input
								required
								id="filled-price-1"
								className="input-text"
								type="file"
								capture="environment"
								accept="image/*"
								onChange={(e) => handlePriceUploadChange(e)}
								style={{ margin: "5px auto" }}
							/>
							<p className="disclaimer">
								Please click picture of the price label showing product name and
								price or just click the picture of your receipt. Thank you!
							</p>
						</div>
					)}

					{error && <Alert variant="danger">{error}</Alert>}
					{loading ? (
						<img
							src={loadingLogo}
							className="logo"
							style={{
								height: "2rem",
								width: "2rem",
								margin: "0 5px",
							}}
							alt=""
						/>
					) : (
						<Button className="signup-button" onClick={(e) => handleSubmit(e)}>
							Submit
						</Button>
					)}
				</Box>
			)}
		</div>
	);
}
